import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Button as ReactButton } from 'react-bootstrap';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import RemoveIcon from 'ui-kit/icons/remove-icon/remove-icon';
import CogIcon from 'ui-kit/icons/cog-icon/cog-icon';

import { ButtonProps } from './button.props';
import './button.style.scss';
import Camera from '../icons/camera/camera';

const Button = ({
    async,
    label,
    onClick,
    type,
    variant,
    disabled,
    className,
    chevron,
    isBusy,
    dataGAFormName,
    dataGAFormStep,
    dataGAFormStepName,
    dataGAType,
    dataGALocation,
    cameraIcon
}: ButtonProps): ReactElement => {
    const classes = classNames(className, { 'is-busy': isBusy });
    return (
        <ReactButton
            className={classes}
            variant={variant ?? 'primary'}
            type={type}
            onClick={onClick}
            disabled={disabled}
            data-ga-form-step={dataGAFormStep}
            data-ga-form-stepname={dataGAFormStepName}
            data-ga-form-name={dataGAFormName}
            data-ga-type={dataGAType}
            data-ga-location={dataGALocation}
        >
            {variant === 'back' && (
                <ChevronIcon
                    direction={'left'}
                    style={{
                        backgroundColor: '#1a497f',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '0 50% 50% 0',
                        width: '30px',
                        height: '30px',
                        marginLeft: '0',
                        marginRight: '10px'
                    }}
                />
            )}
            {cameraIcon && <Camera className="mr-2" style={{ width: '24px' }} />}
            {variant === 'remove' && <RemoveIcon />}
            <div className="btn-label">{label}</div>
            {async && <CogIcon className="btn-cog-icon" style={{ height: '100%', width: '2rem' }} />}
            {chevron && <ChevronIcon direction={chevron} />}
        </ReactButton>
    );
};

export default Button;
